import React from 'react';
import { useNavigate } from "react-router-dom";

export const AddEventButton = ({ onClick, style, className }) => {
    let navigate = useNavigate();
    const styling = {
        cursor: 'pointer',
        borderRadius: '10px',
        color: 'white',
        backgroundColor: ' #879fcf',
        border: ' 3px solid white',
        boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6), 0px -5px 0px 0px rgba(0, 0, 0, 0.08) inset',
        width: '45px',
        height: '45px'
    }

    const mergedStyles = {
        ...styling,
        ...style, // merge the provided style prop
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={mergedStyles}
            onClick={onClick}
            className={className}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
        </svg>
    )
}
