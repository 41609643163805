import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ListItemButton from '@mui/material/ListItemButton';
import React, { useEffect, useState, Fragment } from 'react';
import Confetti from 'react-dom-confetti';
import { useNavigate } from "react-router-dom";
import lscache from 'lscache';
import { useGeolocated } from 'react-geolocated';
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { AddEventButton } from './components/Buttons/AddEventButton';
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import './events.css';

function calculateDistance(lat1, lon1, lat2, lon2) {
  // Radius of the Earth in kilometers
  const radius = 6371;

  // Convert degrees to radians
  const lat1Rad = lat1 * Math.PI / 180;
  const lon1Rad = lon1 * Math.PI / 180;
  const lat2Rad = lat2 * Math.PI / 180;
  const lon2Rad = lon2 * Math.PI / 180;

  // Haversine formula
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance = radius * c;

  return distance;
}

function getNearestCity(coords,citiesO) {
  if (!coords || citiesO.length == 0) return 'Canada';
  let minDistance=999999999999;
  let minCity='Canada';
  citiesO.map((c)=>{	 
     let minD=999999999999;
	 if (c.latitude!=0) minD=calculateDistance(coords.latitude,coords.longitude,c.latitude,c.longitude);
	 if (minD<minDistance) {
	   minDistance=minD;
	   minCity=c.cname;
	 }
  });
  console.log('distance',minCity,minDistance);
  if (minDistance<80) return minCity;
  else return 'Canada';
}

function Events() {
    const [events, setEvents] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [showPastEvents, setShowPastEvents] = useState(false);
    const [showFutureEvents, setShowFutureEvents] = useState(false);
    const [confettiActive, setConfettiActive] = useState(false);

	const [cities, setCities] = useState([])
	const [citiesO, setCitiesO] = useState([])
	const [city, setCity] = useState('')

	const [showmore, setShowmore] = useState(false)
	const [userInput, setUserInput] = useState('')

    let navigate = useNavigate();

    useEffect(() => {
		fetchCities();
        // update current date and time every second
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 2000000000);

        return () => {
            clearInterval(timer);
        };
    }, []);

	useEffect(() => {
        fetchEvents();
    }, [city]);

	  const fetchCities = () => {
		var requestOptions = {
		  method: 'GET',
		  redirect: 'follow'
		};
		fetch(getUrl("cities"), requestOptions)
		  .then(response => response.text())
		  .then(result => { let c=JSON.parse(result);  
				setCitiesO(c);
				setCities(c.map(city=>{return(city.cname)}));  
				} )
		  .catch(error => console.log('error', error));
		let ucity=lscache.get('city');
		
		if (ucity != null) { setCity(ucity); console.log('ucity',ucity);}
	  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
	let c=lscache.get('coords');
	console.log('getcoords',coords,c,citiesO.length);
    if (coords && c === null ) {
	  if (citiesO.length>0) lscache.set('coords',{'lat':coords.latitude,'lon':coords.longitude},1);

	  let ucity=lscache.get('city');	
	  console.log('ucity',ucity);
	  if (ucity != null) { setCity(ucity); console.log('ucity',ucity);}
	  else {
		    console.log('getNearestCity');
		    let nearestcity = getNearestCity(coords,citiesO);
			if (nearestcity!=='Canada') {
		      setCity(nearestcity); 
		      lscache.set('city',nearestcity,1); // refresh current city every 1min 
			}
		  }
	}
  }, [coords,citiesO]);

    const fetchEvents = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(getUrl('events'), requestOptions)
            .then((response) => response.json())
            .then((result) => {
                let sortedEvents = result.sort((a, b) => {
                    const dateA = new Date(`${a[2]}T${a[3]}`);
                    const dateB = new Date(`${b[2]}T${b[3]}`);
                    return dateA - dateB;
                });
				if (city!='' && city != 'Canada' ) // Canada means no filter at all
				  {
					console.log('filter by city',city);
					let ltmp = sortedEvents;
					sortedEvents = ltmp.filter((a) => 
					  { return a[12].toLowerCase() === city.toLowerCase(); }
				  );
				  }
                setEvents(sortedEvents);
            })
            .catch((error) => console.log('error', error));
    };

    // console.log(events);

    const renderPriceType = (eventType, eventData) => {
        const greenStyle = { color: 'black' };

        if (eventType === 'Free') {
            return <span style={greenStyle}>Free</span>;
        } else if (eventType === 'Pay') {
            return <span style={greenStyle}>${eventData[10] || ''}</span>;
        } else if (eventType === 'Performer') {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '-30px',
                        // color:'green'
                    }}
                >
                    <img src="./assets/logo2.png" alt="Performer" style={{ height: '50px', width: '50px', marginTop: '10px' }} />
                    <p style={{ height: '10px', margin: '0', fontSize: '11px', textAlign: 'center' }}>Remember to<br />Tip!</p>
                </div>
            );
        }

        return null;
    };

    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':');
        let formattedTime = '';
        let period = '';

        if (parseInt(hours) === 12) {
            formattedTime = hours;
            period = 'PM';
        } else if (parseInt(hours) > 12) {
            formattedTime = (parseInt(hours) - 12).toString();
            period = 'PM';
        } else {
            formattedTime = hours;
            period = 'AM';
        }

        return `${formattedTime}:${minutes} ${period}`;
    };

    const getFormattedDate = (dateString) => {
        const eventDate = new Date(dateString);
        const currentDate = new Date();
        // adjust for the timezone offset
        const timezoneOffset = currentDate.getTimezoneOffset() * 60 * 1000;
        eventDate.setTime(eventDate.getTime() + timezoneOffset);
        currentDate.setHours(0, 0, 0, 0); // Set the time part of the current date to 00:00:00

        //console.log('currentDate',currentDate+'|'+eventDate);
        // Check if the event date is the same as the current date
        if (eventDate.getTime() === currentDate.getTime()) {
            return 'Today';
        }

        // Check if the event date is tomorrow
        const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1)); // get the date 1 day from now
        //console.log('Tomorrow',tomorrow+'|'+eventDate);
        if (eventDate.getTime() === tomorrow.getTime()) {
            return 'Tomorrow';
        }

        const timeZone = 'UTC';
        // For other dates, return the full formatted date
        const fullOptions = {
            timeZone,
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return eventDate.toLocaleDateString(undefined, fullOptions);
    };


    // const isPastEvent = (dateString) => {
    //     const eventDate = new Date(dateString);
    //     const currentDate = new Date();
    //     return eventDate.getYear() < currentDate.getYear() || ((eventDate.getDate() + 1 < currentDate.getDate() && eventDate.getMonth() <= currentDate.getMonth()));
    // };

    // compare the entire event date with the current date
    const isPastEvent = (dateString) => {
        const eventDate = new Date(dateString);
        const currentDate = new Date();
        // adjust for the timezone offset
        const timezoneOffset = currentDate.getTimezoneOffset() * 60 * 1000;
        eventDate.setTime(eventDate.getTime() + timezoneOffset);
        currentDate.setHours(0, 0, 0, 0); // Set the time part of the current date to 00:00:00
        return eventDate.getTime() < currentDate.getTime();
    };



    const isFutureEvent = (dateString) => {
        const eventDate = new Date(dateString);
        const currentDate = new Date();
        // adjust for the timezone offset
        const timezoneOffset = currentDate.getTimezoneOffset() * 60 * 1000;
        eventDate.setTime(eventDate.getTime() + timezoneOffset);
        currentDate.setHours(0, 0, 0, 0); // Set the time part of the current date to 00:00:00
        const twoWeeksLater = new Date(currentDate.setDate(currentDate.getDate() + 14)); // get the date 2 weeks from now
        return eventDate.getTime() > twoWeeksLater.getTime();
    };


    const togglePastEvents = () => {
        setShowPastEvents((prevShowPastEvents) => !prevShowPastEvents);
    };

    const toggleFutureEvents = () => {
        setShowFutureEvents((prevShowFutureEvents) => !prevShowFutureEvents);
    };

    // if event name/event place too long, give them a new sentence
    function splitText(text) {
        if (text.length > 15) {
            const words = text.split(' ');
            let firstSentence = words[0];
            let remainingText = '';

            for (let i = 1; i < words.length; i++) {
                if ((firstSentence + ' ' + words[i]).length <= 15) {
                    firstSentence += ' ' + words[i];
                } else {
                    remainingText = words.slice(i).join(' ');
                    break;
                }
            }

            return (
                <div>
                    {firstSentence}
                    <br />
                    {remainingText}
                </div>
            );
        }

        return text;
    }

    //confetti setup
    const toggleConfetti = () => {
        setConfettiActive(true);
        setTimeout(() => {
            setConfettiActive(false);
            window.location.href = 'https://www.eventbrite.ca/e/city-tour-and-party-tickets-710117890727?aff=ebdshpsearchautocomplete&keep_tld=1';
        }, 1000);
    };


    const onSChange = (e) => {
    const suggestions = cities;
    const userInput = e.currentTarget.value;    
    setUserInput(userInput);

  };
  const onSClick = (e) => {
      console.log('onSClick',e.target.childNodes[0].nodeValue);
      if (e.target.childNodes[0].nodeValue=='more') setShowmore(true);
      else if (e.target.childNodes[0].nodeValue=='less') setShowmore(false);
      else {
        setCity(e.target.childNodes[0].nodeValue);
        lscache.set('city',e.target.childNodes[0].nodeValue,5); // keep selected city for 5 min
        setUserInput('');  
      }
  };
  const suggestionsListComponent = (e,showmore) => {  
     let renderSuggestions = [];
     if (!userInput) return (<div></div>);
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = cities.filter((suggestion) =>
      suggestion.toLowerCase().startsWith(userInput.toLowerCase())
    );
	if (filteredSuggestions.length==1) { //auto complete
		setCity(filteredSuggestions[0]);
        lscache.set('city',filteredSuggestions[0],5); // keep selected city for 5 min
        setUserInput('');  
		return (<div></div>);
	}
    if (filteredSuggestions.length > 3 && showmore) {
         renderSuggestions = filteredSuggestions
    } else renderSuggestions = filteredSuggestions.slice(0,3);
    return (<div style={{ display: "flex", "flex-wrap": "wrap", width: "95vw", 'margin-left':'4vw' }}>
		<div className="suggestion-active" style={{'background-color':'#d3a6e5'}}onClick={onSClick} >Canada</div>
        {renderSuggestions.map((suggestion, index) => {
              let className;
              className = "suggestion-active";
              return (
                <div className={className} key={suggestion} onClick={onSClick}>
                  {suggestion}
                </div>
              );
            })} 
          {filteredSuggestions.length>3 && !showmore ? 
              <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >more</div> 
              :
              filteredSuggestions.length>3 ? <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >less</div> :''
          }     
    
       </div>);
  };

    return (
        <div>
            <DesktopContainer />
            <div className="events-container mobile-container">
                <div className='top-group'>
                    <BackButton onClick={() => navigate("/suggestion")} />
                    <Scoreboard className='scoreboard-E' onClick={() => navigate('/redeem')} />
                    <AddEventButton onClick={() => navigate('/addevent')} />
                    {/* <div className="current-date-time">Current Date and Time: {currentDateTime.toLocaleString()}</div> */}
                </div>

			<Fragment>
				<div style={{display:'flex', 'padding-left':'4vw', alignItems:'center'}}>
				<input
				  type="text"
				  onChange={onSChange}          
				  value={userInput}
				  style={{width: '90vw', padding:'10px','border-radius':'10px',display:'block'}}
				  placeholder=" 🔍 Select a City"
				/>
				<div style={{
						right: '25vw',
						top: '1.4vh',
						display: 'flex',
						'background-color': '#9ff196',
						width: '23vw',
						height: '7vw',
						'border-radius': '8px',
						'justify-content': 'center',
						marginLeft: '-25vw',
						alignSelf:'center',
					}}><p style={{'align-self':'center','font-size':'small'}}>{city}</p></div>
				</div>
				{suggestionsListComponent(userInput,showmore)}	    
			  </Fragment>


                <ol className='div-containers'>
                    {/* Quest mode */}
                    <div className='questmode' style={{ display: 'none' }} >
                        <ListItemButton
                            className="list-item-btn quest-mode"
                            sx={{
                                backgroundColor: 'white',
                                marginTop: '15px',
                                marginLeft: '-25px',
                                marginBottom: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                color: 'black',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.4);',
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={toggleConfetti}
                        >
                            <div class="event-container-EQ">
                                <div class="left-column">
                                    <h3 class="date-header">Sept 15th</h3>
                                    <div class="event-details-row">
                                        <FmdGoodIcon style={{ marginTop: '10px', marginRight: '6px', marginLeft: '-5px', color: "#EB6660" }} />
                                        <h3 class="event-name-EQ">Hidden Gemz</h3>
                                    </div>
                                </div>
                                <div class="middle-column"> {splitText('City Party and Launch')}</div>
                                <div class="right-column">
                                    <h3 class="price-EQ">$30</h3>
                                    <div class="time EQ">
                                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                                            <AccessTimeIcon style={{ transform: 'scale(0.9)', marginTop: '14px', color: '#81A0D3', marginRight: '5px' }} />
                                            <h4 class="start-time">7:00 PM</h4>
                                        </div>
                                        <h4 class="end-time" style={{ marginRight: "-30px", fontWeight: '200', marginTop: '-2vh' }}>11:59 PM</h4>
                                    </div>
                                </div>
                            </div>
                        </ListItemButton>
                    </div>
                    {/* Render Today's Events */}
                    {events.map((event, index) => {
                        const currentDate = event[2];
                        const previousDate = index > 0 ? events[index - 1][2] : null;
                        const showDate = currentDate !== previousDate;
                        const isPast = isPastEvent(currentDate);
                        const isFuture = isFutureEvent(currentDate);

                        if (isPast) {
                            return null;
                        }

                        return (
                            <div key={`${event[0]}_${index}`}>
                                {showDate && !isFuture && <div className="event-date" style={{ marginTop: '20px' }}>{getFormattedDate(currentDate)}</div>}
                                {!isFuture && (
                                    <ListItemButton
                                        className="list-item-btn"
                                        sx={{
                                            backgroundColor: 'white',
                                            marginTop: '15px',
                                            marginLeft: '-25px',
                                            marginBottom: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            textAlign: 'center',
                                            color: 'black',
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.4);',
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                            },
                                        }}
                                        onClick={() => navigate(`/eventpage/${event[0]}/${event[2]}`)}
                                    // navigate to /eventpage/:eventName
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div className="event-name-place" style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div className="event-name" style={{ textAlign: 'left' }}>
                                                    {splitText(event[0])}
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
                                                    <FmdGoodIcon style={{ marginTop: '-5px', marginRight: '6px', marginLeft: '-5px', color: "#EB6660" }} />
                                                    <div className="event-place">{splitText(event[6])}</div>
                                                </div>
                                            </div>
                                            <div>
												<div style={{display:'flex', height:'3.8vh'}}>
												<div className="suggestion-active" style={{'background-color':'rgb(212 226 251)','color':'blue'}} >{event[12]}</div>
                                                <div className="price-type">{renderPriceType(event[9], event)}</div>
                                                </div>
												<div className="time" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '-30px', marginTop: "15px", fontWeight: '500' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <AccessTimeIcon style={{ transform: 'scale(0.9)', marginTop: '-4px', color: '#81A0D3', marginRight: '5px' }} />
                                                        <div className="start-time">{convertTo12HourFormat(event[3])}</div>
                                                    </div>
                                                    <div className="end-time" style={{ marginRight: "-30px", fontWeight: '200' }}>{convertTo12HourFormat(event[4])}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItemButton>
                                )}
                            </div>
                        );
                    })}

                    {/* Future events */}
                    {events.some((event) => isFutureEvent(event[2])) && (
                        <div>
                            <div className="event-date" onClick={toggleFutureEvents} style={{ display: 'flex', alignItems: 'center' }}>
                                {showFutureEvents ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                {showFutureEvents ? 'Future Events' : 'Future Events'}
                            </div>
                            {showFutureEvents &&
                                events.map((event, index) => {
                                    const currentDate = event[2];
                                    const isFuture = isFutureEvent(currentDate);

                                    if (!isFuture) {
                                        return null; // skip rendering non-future events in the future events section
                                    }

                                    return (
                                        <div key={`future_${event[0]}_${index}`}>
                                            <ListItemButton
                                                className="list-item-btn"
                                                sx={{
                                                    backgroundColor: 'white',
                                                    marginLeft: '-25px',
                                                    marginBottom: '20px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '10px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    textAlign: 'center',
                                                    color: 'black',
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.4);',
                                                    transition: 'transform 0.3s ease',
                                                    '&:hover': {
                                                        transform: 'scale(1.05)',
                                                    },
                                                }}
                                                onClick={() => navigate(`/eventpage/${event[0]}/${event[2]}`)}

                                            >
                                                {/* Render future events */}
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div className="event-name-place" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div className="event-name" style={{ textAlign: 'left' }}>
                                                            {splitText(event[0])}
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
                                                            <FmdGoodIcon style={{ marginTop: '-5px', marginRight: '6px', marginLeft: '-5px', color: "#EB6660" }} />
                                                            <div className="event-place">{splitText(event[6])}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{display:'flex', height:'3.8vh'}}>
														<div className="suggestion-active" style={{'background-color':'rgb(212 226 251)','color':'blue'}} >{event[12]}</div>
														<div className="price-type">{renderPriceType(event[9], event)}</div>
														</div>
                                                        <div className="time" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '-30px', marginTop: "15px" }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <AccessTimeIcon style={{ transform: 'scale(0.9)', marginTop: '-4px', color: '#81A0D3', marginRight: '5px' }} />
                                                                <div className="start-time">{convertTo12HourFormat(event[3])}</div>
                                                            </div>
                                                            <div className="end-time" style={{ marginRight: "-30px", fontWeight: '200' }}>{convertTo12HourFormat(event[4])}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListItemButton>
                                        </div>
                                    );
                                })}
                        </div>
                    )}

                    {/* Past events */}
                    {/* {events.some((event) => isPastEvent(event[2])) && (
                        <div>
                            <div className="event-date" onClick={togglePastEvents} style={{ display: 'flex', alignItems: 'center' }}>
                                {showPastEvents ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                {showPastEvents ? 'Past Events' : 'Past Events'}
                            </div>
                            {showPastEvents &&
                                events.map((event, index) => {
                                    const currentDate = event[2];
                                    const isPast = isPastEvent(currentDate);

                                    if (!isPast) {
                                        return null; // skip rendering future events in the past events section
                                    }

                                    return (
                                        <div key={`past_${event[0]}_${index}`}>
                                            <ListItemButton
                                                className="list-item-btn"
                                                sx={{
                                                    backgroundColor: '#a1daed',
                                                    marginLeft: '-15px',
                                                    marginBottom: '20px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '10px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    textAlign: 'center',
                                                    color: 'black',
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                    transition: 'transform 0.3s ease',
                                                    '&:hover': {
                                                        transform: 'scale(1.05)',
                                                    },
                                                }}
                                                onClick={() => navigate(`/eventpage/${event[0]}/${event[2]}`)}

                                            >  */}
                    {/* Render past events */}
                    {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div className="time" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <div className="start-time">{convertTo12HourFormat(event[3])}</div>
                                                        <div className="end-time" style={{ color: 'grey' }}>{convertTo12HourFormat(event[4])}</div>
                                                    </div>
                                                    <div className="event-name-place" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <div className="event-name">{splitText(event[0])}</div>
                                                        <div className="event-place">{splitText(event[6])}</div>
                                                    </div>
                                                    <div className="price-type">{renderPriceType(event[9], event)}</div>
                                                </div>
                                            </ListItemButton>
                                        </div>
                                    );
                                })}
                        </div>
                    )}  */}
                </ol>
                <Confetti active={confettiActive} config={{ angle: 90, spread: 560, startVelocity: 100, elementCount: 800, decay: 0.9 }} />
            </div>
        </div>
    );


}

export default Events;
