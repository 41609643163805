import '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddEvent from './AddEvent';
import AddLocationUsers from './AddLocationUsers';
import EventPage from './EventPage';
import Events from './Events';
import HomePage from './HomePage';
import Interests from './Interests';
import getUrl from './LambdaUrls';
import Leaderboard from './Leaderboard';
import Newsfeed from './Newsfeed';
import Redeem from './Redeem';
import User from './User';
import UserOrOtherUser from './UserOrOtherUser';
import Voting from './Voting';
import VotingPlaceView from './Voting-Placeview';
import SignInPage from './components/SignInPage';
import SignUpPage from './components/SignUpPage';
import VerificationCode from './components/VerificationCode';
import awsExports from './configuration';
import dairExports from './configuration-dair';
import { UserProfileSetup } from './helper/profileAPIHelper';
import './index.css';
import ListView from './listView';
import Map from './map';
import MapAll from './mapAll';
import PlaceView from './placeView';
import Suggestion from './suggestions';
import Desktop from './Desktop';
import ReactGA from 'react-ga4';

if (getUrl('cognito') == 'PROD') { Amplify.configure(awsExports); } else { Amplify.configure(dairExports); }

ReactGA.initialize('G-JTL64S6HDF', {
  gtagOptions: { debug_mode: true }
});

const App = () => {
  const [user, setUser] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const location = useLocation();
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const checkUserSignedIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('User:', user);
      setUserEmail(user.attributes.email);
      setIsUserSignedIn(true);
      localStorage.setItem('accessToken', user.signInUserSession.idToken.jwtToken);
    } catch (error) {
      console.log('Error:', error);
      setIsUserSignedIn(false);
      localStorage.removeItem("email");
      localStorage.removeItem("accessToken");
    }
  };
  
  
  useEffect(() => {
    console.log(location.pathname, location.search);
    document.title = location.pathname + location.search
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
}, [location]);

  useEffect(() => {
    checkUserSignedIn();
  }, []);

  const signOut = async () => {
    await Auth.signOut();
    setUser(false);
    window.location.href = "/signin";
  };

  return (
    <div>
      <Routes location={location}>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignInPage onSignIn={checkUserSignedIn} />} />
        <Route
          path="/signup"
          element={<SignUpPage setIsSignedUp={setIsSignedUp} />}
        />
        <Route
          path="/verification"
          element={isSignedUp ? <VerificationCode /> : <Navigate to="/signup" />}
        />
        {/* 
        <Route path="/verification" element={<VerificationCode />} /> */}
        <Route path="/Desktop" element={<Desktop />} />
        <Route path="/suggestion" element={<Suggestion />} />
        <Route path="/listView" element={<ListView />} />
        <Route path="/placeView/:placeName/:placeID" element={<PlaceView />} />
        <Route path="/map/:placeName/:placeID" element={<Map />} />
        <Route path="/mapAll" element={<MapAll />} />
        <Route path="/add-location" element={<AddLocationUsers />} />
        {/* <Route path="/feedback" element={<Feedback />} /> */}
        <Route path="/redeem" element={<Redeem />} />
        <Route path="/addevent" element={<AddEvent />} />
        <Route path="/events" element={<Events />} />\
        <Route path="/user" element={<User />} />
        <Route path="/user/:pfullname" element={<UserOrOtherUser />} />
        <Route path="/voting" element={<Voting />} />
        <Route path="/interests" element={<Interests />} />
        <Route path="/voting-placeview/:placeName/:placeID" element={<VotingPlaceView />} />
        <Route path="/eventpage/:eventName/:eventDate" element={<EventPage />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/newsfeed" element={<Newsfeed />} />
        <Route path="/settings" element={<UserProfileSetup />} />
        <Route path="*" element={<HomePage />} />
        <Route path="/PlaceToEat" element={<ListView />}  />
        <Route path="/FunThingsToDo" element={<ListView />}  />
      </Routes>
    </div>

  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Router>
    <ToastContainer />
    <App />
  </Router>
);
