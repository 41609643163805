import React, { useRef, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ["places"];

const AutocompleteInput = ({
    id, label, value, onChange, setPlaceName, setAddress
}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDjbVgoqH2yW8dWMRW3GOli40Kyiyyc54o", // Replace with your API key
        libraries,
    });

    const inputRef = useRef(null);
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (isLoaded && inputRef.current && !autocompleteRef.current) {
            autocompleteRef.current = new window.google.maps.places.Autocomplete(
                inputRef.current,
                { types: ['establishment', 'geocode'] } // Can specify types to improve autocomplete suggestions
            );
            autocompleteRef.current.addListener("place_changed", () => {
                const place = autocompleteRef.current.getPlace();
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                // Decide if the input is a place name or an address based on the type
                if (place.types && place.types.includes('establishment')) {
                    // If it's an establishment, use the place name
                    setPlaceName(place.name);
                    setAddress(place.formatted_address); // Sometimes place might not have a formatted address
                } else if (place.types.some(type => type === 'geocode')) {
                    // If it's just an address, set both to the address
                    setAddress(place.formatted_address);
                    setPlaceName(place.formatted_address); // Optionally, you might not want to do this
                } else {
                    // Fallback to address if type is undefined or unexpected
                    setAddress(place.formatted_address || '');
                    setPlaceName(place.name || '');
                }
            });
        }
    }, [isLoaded, setPlaceName, setAddress]);

    return (
        <div className="formGroup-USERS">
            <label htmlFor={id} className="formLabel-USERS">{label}:</label>
            <input
                type="text"
                id={id}
                className="formInput-USERS"
                ref={inputRef}
                value={value}
                onChange={e => onChange(e.target.value)}
                required
            />
        </div>
    );
};

export default AutocompleteInput;
